import * as React from "react";
import { StoreProvider } from "./src/components/StoreContext";
import TagManager from "@sooro-io/react-gtm-module";

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <StoreProvider>{element}</StoreProvider>
);

const trackingId = "GTM-K478CWF3";

const currentEnvironment =
  process.env.ENV || process.env.NODE_ENV || "development";

let isGtmInitialized = false;

export const initializeGtm = async () => {
  if (currentEnvironment !== "production") {
    return;
  }

  if (!isGtmInitialized) {
    const userHasAccepted = localStorage.getItem("consentGiven");
    if (userHasAccepted) {
      TagManager.initialize({
        gtmId: trackingId,
      });
      isGtmInitialized = true;
    }
  }
};

// This code potentially is needed to push custom "gatsby-route-change" event, but looks like we don't need it.

// const currentEnvironment =
//   process.env.ENV || process.env.NODE_ENV || "development";
// const routeChangeEventName = "gatsby-route-change";

// const updateGtmDataLayer = async (location: Location) => {
//   if (isGtmInitialized) {
//     const userHasAccepted = localStorage.getItem("consentGiven"); // get local storage value here

//     if (userHasAccepted) {
//     TagManager.dataLayer({
//       dataLayer: {
//         event: routeChangeEventName,
//         value: location.pathname,
//       },
//     });
//     }
//   }
// };

// export const onRouteUpdate = ({ location }: { location: Location }) => {
//   // if (currentEnvironment !== "production") {
//   //   return;
//   // }

//   updateGtmDataLayer(location);
// };

// if needed below function is executed on application start, so can be used to initialize GTM
// export const onClientEntry = (): void => {
//   if (currentEnvironment !== "production") {
//     return;
//   }

//   initializeGtm();
// };
