import * as React from "react";
import ShopifyBuy, { Checkout } from "shopify-buy";
import { useShopItems } from "../lib/useShopItems";
import { getCountryCodeByIp } from "../lib/getCountryCodeByIp";

const client = ShopifyBuy.buildClient({
  apiVersion: "2023-01",
  // tslint:disable-next-line: no-non-null-assertion
  domain: process.env.GATSBY_SHOPIFY_MYSHOPIFY_URL!, // can be custom domain
  // tslint:disable-next-line: no-non-null-assertion
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN!,
});

interface IReaderContext {
  loading: boolean;
  addVariantToCart: (variantId: string, quantity: number) => Promise<void>;
  removeLineItem: (checkoutID: string, lineItemID: string) => Promise<void>;
  updateLineItem: (
    checkoutID: string,
    lineItemID: string,
    variantId: string,
    quantity: number
  ) => Promise<void>;
  updateNote: (checkoutID: string, note: string) => Promise<void>;
  client: ShopifyBuy;
  checkout: Checkout | null;
}

export const StoreContext = React.createContext<IReaderContext>(
  {} as unknown as IReaderContext
);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

interface IProps {
  children: React.ReactNode;
}
export const StoreProvider: React.FC<IProps> = ({ children }) => {
  const [checkout, setCheckout] = React.useState<Checkout | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const { strapiItems } = useShopItems();

  const updateCheckout = (newCheckout: Checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, String(newCheckout.id));
    }

    setCheckout(newCheckout);
  };

  const removeLineItem = (checkoutID: string, lineItemID: string) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          );
          if (!existingCheckout.completedAt) {
            updateCheckout(existingCheckout);

            // remove items that no longer exist
            await Promise.all(
              existingCheckout.lineItems.map(async (lineItem) => {
                const shopifyProductId = lineItem.variant?.product.id
                  .split("/")
                  .pop();

                const strapiItem = strapiItems.find(
                  (item) => item.shopifyProductId === shopifyProductId
                );

                if (!strapiItem) {
                  await removeLineItem(existingCheckout.id, lineItem.id);
                }
              })
            );

            return;
          }
        } catch (e) {
          localStorage.removeItem(localStorageKey);
        }
      }

      const countryCode = await getCountryCodeByIp();

      try {
        const newCheckout = await client.checkout.create(
          countryCode === "UA"
            ? ({
                buyerIdentity: {
                  countryCode: "UA",
                },
              } as any)
            : undefined
        );
        updateCheckout(newCheckout);
      } catch (e: any) {
        throw new Error("Failed to create new checkout: " + e.message);
      }
    };

    // tslint:disable-next-line: no-floating-promises
    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId: string, quantity: number) => {
    setLoading(true);

    // tslint:disable-next-line: no-non-null-assertion
    const checkoutID = checkout!.id;

    const lineItemsToAdd = [
      {
        variantId,
        quantity,
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToAdd)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
      });
  };

  const updateLineItem = (
    checkoutID: string,
    lineItemID: string,
    variantId: string,
    quantity: number
  ) => {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, variantId, quantity }];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateNote = (checkoutID: string, note: string) => {
    setLoading(true);

    return client.checkout
      .updateAttributes(checkoutID, { note })
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const contextObject = {
    loading,
    addVariantToCart,
    removeLineItem,
    updateLineItem,
    updateNote,
    checkout,
    didJustAddToCart,
    client,
  };

  return (
    <StoreContext.Provider value={contextObject}>
      {children}
    </StoreContext.Provider>
  );
};
