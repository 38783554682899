export async function getCountryCodeByIp(): Promise<string> {
  const cachedCountryCode = localStorage.getItem("deliveryCountryCode");
  const cachedCountryCodeDate = localStorage.getItem("deliveryCountryCodeDate");

  // check if country code is cached and not older than 10 hours
  if (
    cachedCountryCode &&
    cachedCountryCodeDate &&
    new Date(cachedCountryCodeDate) > new Date(Date.now() - 1000 * 60 * 60 * 10) // 10h
  ) {
    return cachedCountryCode;
  }

  let countryCode = "GB";

  try {
    const response = await fetch("https://ip.svitanok.com/");

    if (!response.ok) {
      return countryCode;
    }

    const { country } = await response.json();
    countryCode = country;

    localStorage.setItem("deliveryCountryCode", countryCode);
    localStorage.setItem("deliveryCountryCodeDate", new Date().toISOString());

    return countryCode;
  } catch (error: any) {
    return countryCode;
  }
}
