exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-shop-cart-tsx": () => import("./../../../src/pages/shop/cart.tsx" /* webpackChunkName: "component---src-pages-shop-cart-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-templates-concept-group-tsx": () => import("./../../../src/templates/conceptGroup.tsx" /* webpackChunkName: "component---src-templates-concept-group-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-shop-item-tsx": () => import("./../../../src/templates/shopItem.tsx" /* webpackChunkName: "component---src-templates-shop-item-tsx" */)
}

