import { graphql, useStaticQuery } from "gatsby";

interface IShopItems {
  shopifyItems: readonly Queries.ShopifyProduct[];
  strapiItems: readonly Queries.STRAPI_SVITANOK_SHOP_ITEM[];
}

export function useShopItems(): IShopItems {
  const result = useStaticQuery<{
    allShopifyProduct: Queries.ShopifyProductConnection;
    allStrapiSvitanokShopItem: Queries.STRAPI_SVITANOK_SHOP_ITEMConnection;
  }>(graphql`
    {
      allShopifyProduct {
        nodes {
          storefrontId
          productType
          title
          descriptionHtml
          seo {
            description
            title
          }
          priceRangeV2 {
            maxVariantPrice {
              currencyCode
              amount
            }
            minVariantPrice {
              currencyCode
              amount
            }
          }
          options {
            name
            position
            values
          }
          variants {
            storefrontId
            title
            weight
            media {
              ... on ShopifyMediaImage {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            contextualPricingUA {
              price {
                amount
                currencyCode
              }
            }
            weightUnit
          }
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          media {
            id
            ... on ShopifyMediaImage {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      allStrapiSvitanokShopItem {
        nodes {
          id
          shopifyProductId
          priority
          topDescription {
            data {
              topDescription
            }
          }
          mainDescription {
            data {
              mainDescription
            }
          }
          dimensionsDescription {
            data {
              dimensionsDescription
            }
          }
          materialsDescription {
            data {
              materialsDescription
            }
          }
          downloadsDescription {
            data {
              downloadsDescription
            }
          }
          imageGallery {
            id
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          variantGallery {
            id
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          dimensionsImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          autoCadUrl
          maxUrl
          revitUrl
          getInspiredProducts {
            shopifyProductId
          }
          identityTitle
        }
      }
    }
  `);

  const sortedStrapiNodes = [...result.allStrapiSvitanokShopItem.nodes];
  sortedStrapiNodes.sort((p1, p2) => (p2.priority ?? 0) - (p1.priority ?? 0));

  const sortedShopifyNodes = sortedStrapiNodes
    .map((strapiNode) =>
      result.allShopifyProduct.nodes.find((shopifyNode) => {
        const shopifyProductId = shopifyNode.storefrontId.split("/").pop();
        return shopifyProductId === strapiNode.shopifyProductId;
      })
    )
    .filter((node) => node !== undefined) as Queries.ShopifyProduct[];

  return {
    shopifyItems: sortedShopifyNodes,
    strapiItems: sortedStrapiNodes,
  };
}
